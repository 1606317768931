@charset "UTF-8";
/*Body*/
html {font-size: 18px;}
body {font-family:'Montserrat', Fallback, sans-serif; font-size: 1rem; color: #282829;}
h1,h2,h3,h4,h5,h6 {letter-spacing: 2px;}
figure {margin:0;padding:0}

/*Home Page and Sidebar Buttons*/
.htpc-button {background-color: #005C69; color: #F0F0F0; border-radius: 3px; border: 1px solid rgba(0,0,0,0.2);}
.htpc-button:hover {background-color: #00A0B5; color: #000000}
.help-button, .help-section button, .updated button, .small-box a {background-color: #930345; color:#fff; border: 1px solid rgba(0,0,0,0.2); border-radius: 3px;}
.small-box a:hover, .help-button:hover{background-color: #E0046B; color: rgba(0,0,0,1);}
.social-share-buttons {display: flex; flex-direction: row; align-items: center;}
.twitter-share {margin-top: 7px;}
.fb-share-button {margin-right: 8px;}

/*Desktop Nav*/
:root {
  --nav-height: 105px;
}
.nav {position: fixed; top: 0; width: 100%; height: var(--nav-height); z-index: 99;background-color:#fff; transition: transform 0.3s ease;}
.nav-items {display: inline-block; width: 70%;}
.nav-items a {margin-top: 12px; font-size: .889rem;}
.main-nav-btns{border:none;display:inline-block;padding:8px 16px;vertical-align:middle;overflow:hidden;text-decoration:none;color:inherit;background-color:inherit;text-align:center;cursor:pointer;white-space:nowrap; transition: color 0.2s; font-weight: 700;}
.main-nav-btns:hover{color:#930345;}
.main-nav-btns{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
/*Skip Link*/
.skip-link {background: white;color: #fff;font-weight: 700;left: 50%;padding: 4px;position: absolute;transform: translateY(-100%);transition: transform 0.3s;}
.skip-link:focus {transform: translateY(0%);}
.logo {margin-left: 25px;margin-right: 15px;; padding-top: 16px;padding-bottom: 24px;display:inline-block; max-width: 80%;}
.logo img {width:225px;height:65px;}
/*Mobile Nav*/
.open-btn {font-size: 1.333rem; position: absolute; top: 37px; left: 30px; z-index: 1;}
.close-btn {font-size: 44px; position: absolute; top: 22px; left: 30px; z-index: 2;}
.mobile-nav, .sub-menu {background-color: #E6E6E6;height: 100%;width: 0;position: fixed;top: 0px;left: 0;overflow-x: hidden;transition: 0.15s;white-space: nowrap;padding-bottom: 125px;}
.mobile-nav {padding-top: 127px; z-index: 1;} .sub-menu {padding-top: 15px; z-index: 2;}
.mobile-nav a {background-color: #E6E6E6;color: #111;padding: 8px 32px 8px 32px;text-decoration: none;font-size: 1rem;display: block;transition: 0.3s;}
.mobile-nav button {background-color: #E6E6E6;color: #111;padding: 8px 32px 8px 32px;text-decoration: none;font-size: 1rem;display: block;transition: 0.3s;width: 100%; text-align: left; border: none;}
.mobile-nav h3 {padding: 8px 32px; margin-bottom: 0px;margin-top: 0px}
.mobile-nav a:hover, .mobile-nav button:hover {color: #d50202;}
.sub-menu span {font-size: 1.389rem;margin-left: 12px;}
/*Hide Nav on scroll*/
body.nav-hidden > .nav {
  transform: translateY(calc(var(--nav-height) * -1));
}
body.nav-hidden #sidenav {
  transform: translateY(calc(var(--nav-height) * -1));
}

.pdf-image {height: 300px; width: auto;}
.view-none {display: none;}
/*Footer*/
.footer-row {background-color:#1A1A1A; clear: both;}
.footer-content {color: white;padding: 24px 32px;overflow: auto;}
.footer-content figure {margin-bottom: 16px;}
/*Intro Boxes*/
.intro-box {padding: 24px; background-color: #8DBBCC; background-image: linear-gradient(180deg, #96C7D9, #8DBBCC);}
.intro-box h1 {margin-top: 0px;}
.intro-box p {font-weight: 600;}
.start-button {font-weight: 700; color: #F0F0F0; background-image: linear-gradient(180deg, #508da9, #1D7899, #1D7899); border-radius: 3px; border: 1px solid #508da9;}

.sticky {position: fixed;top: 0;width: 100%; z-index: 3;padding-left: 12px; padding-right: 16px;}
.content-page {background-color: #F2EFE9;} /*Right Column Background Color*/
/*Side Navigation*/
.sidenavigation {background-color: #EBF7F1; padding-bottom: 115px; color: #0A0A0A; transition: transform 0.3s ease;}
.sidenavigation h5 {margin: 12px 0px 8px 16px;}
.sidenavigation h4 {padding: 12px 0px 8px 16px; margin: 0px;}
.sidenavigation h3 {padding-left: 16px; margin: 10px 0px;}
.sidenavigation a {font-size: .889rem;font-weight: 600;padding-left: 8px;}
.sidenavigation a:hover {color:#FFFFFF;background-color:#4E7863;}
.this-page {background-color: #4E7863; color: #FFFFFF;}
/*Main Content Section*/
#main-content-section {margin-left: 250px;margin-top: 100px;}
#main-content {border-right: 1px solid #E6E6E6;background-color: white;}
article a {color: #0000EE;}
.separater {border-bottom: 2px solid #A62142;width: 85%;margin: 35px auto; clear: both;}
.anchor {scroll-margin-top: 8.5em;}
.quote {color: #A62142;}
/*Next Previous Btns*/
.nav-buttons {padding: 0px 24px;margin-top: 24px;margin-bottom: 24px;}
.bot-nav-buttons {padding: 24px;}
.prvs-nxt {font-weight: 700; color: white;background-image: linear-gradient(180deg, #21735C, #1E6652);border-radius: 3px;}
/*Item page Section Boxes*/
.section-content {padding: 24px;}
.page-sections {border: 1px solid grey; border-radius: 3px; margin-top: 24px;}
.page-sections img {width: 100%; height: auto; border-radius: 3px 3px 0px 0px;}
.section-box {padding: 12px; background-color: #F7F7F7; border-radius: 0px 0px 3px 3px;}
.section-box h2 a {color: black;}
.section-box h2 a:hover {color: #D94016;}
.section-buttons a {font-size: .889rem; font-weight: 700;margin: 8px; border-radius: 3px; transition: 0.2s;}
.section-buttons a {color: #F0F0F0; background-image: linear-gradient(0deg, #991E3D, #930345);}
.section-buttons a:hover {color: #F0F0F0; background-image: linear-gradient(180deg, #991E3D, #930345);}
/*Main Pages*/
.content-box {padding: 24px;}
.content-box h1 {margin-top: 0px;}

table {border-collapse: collapse; width: 100%; border-radius: 3px;box-shadow: 0 0 0 1px #777777;}
td, th {border: 1px solid #272927; text-align: center; padding: 4px;word-break: break-word;}
.table-note {margin-top: 5px;}
/*Contact Form*/
input[type=text], select, textarea {width: 100%;padding: 12px;border: 1px solid #ccc;border-radius: 4px;box-sizing: border-box; /* Make sure that padding and width stays in place */margin-top: 6px;margin-bottom: 16px;resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */}
input[type=submit] {background-color: #005C69; color: #FFFFFF; padding: 8px 16px; border-radius: 3px; border: 1px solid rgba(0,0,0,0.2);}
input[type=submit]:hover {background-color: #00A0B5; color: #000000}
.container {border-radius: 5px;;padding: 20px;}
/*Site Map*/
#site-map-header h1, #site-map-content h2, #site-map-content h3 {margin-top: 12px; margin-bottom: 12px;}
#site-map-content ul {margin: 0px; padding-left: 16px;}
#site-map-content a {text-underline-offset: 3px;}
#site-map-content span, #site-map-content h2 {display: inline;}
#site-map-content span {font-size: 1.333rem; margin-right: 8px; display: inline;}
/*Right Column*/
#right-column {padding: 24px}
#right-column input {width: 100%; padding: 8px 16px; max-width: 350px;}
#right-column button {padding: 8px 16px;margin-top: 16px;}
#right-column img {display: block; margin: 24px auto; width: 100%; height: auto;max-width: 80%;}
#right-column h1 {font-size: 1.333rem;text-align: center;}

/* Validation for forms */
.validation {font-size: 0.9rem;color: rgb(241, 99, 99);}

/* Popup */
.popup {position: fixed;z-index: 10000;top: 0;right: 0;bottom: 0;left: 0;align-content: center;justify-content: center;align-items: center;}
.popup>.popup__shadow {position: fixed;z-index: 10001;top: 0;left: 0;right:0;bottom:0;background-color: rgba(0,0,0,0.8); /* Black w/ opacity */}
.popup>.popup__inner {z-index: 10002;max-width: min(calc(100vw - 50px), 550px);max-height: calc(100vh - 50px);overflow: auto;position: relative;background: white;padding: 2.5rem;border: 1px solid grey;border-radius: 6px;}
.popup>.popup__inner>.popup__close {position: fixed;top: 25px;right: 25px; font-size: 1.333rem; color: black;}

/* Email popup */
#download-pdf-popup img { width: 275px; height: 208px; display: block; margin: 0px auto 48px auto;}
#download-pdf-popup input {margin-bottom: 16px; width: 100%;}
#download-pdf-popup button {background-color: #1E6652; color: #eee;padding: 4px 12px; border: 1px solid #164D3D; border-radius: 3px;}
#download-pdf-popup a {color: #991E3D}
#download-pdf-popup .additional-info {font-size: 0.9rem;}

/* Auth popup */
#authPopup>.popup__inner {max-width: min(calc(100vw - 50px), 350px);}
#authPopup .auth-popup-wrapper {display: flex;flex-direction: column;align-items: stretch;}
#authPopup .btn-google {background-color: #f24848;}
#authPopup .btn-facebook {background-color: #6060ef;}

/* Search box */
.search-box {position: absolute; top: 22px; right: 20px; width: 200px;}
.search-box .gsc-control-cse { margin-top: 15px; width: 100% !important; border: none !important; background: none !important; padding: 0 !important; }
.search-box .gsc-control-cse input.gsc-input {padding: 6px 10px !important;height: 2rem !important;background: none !important;}
.search-box .gsc-control-cse .gsc-search-box {border-radius: 3px;box-shadow: none; margin: 0;}
.search-box .gsc-control-cse td {border: none;text-align: left;padding: 0;}
.search-box .gsc-control-cse .gsc-input-box {border: none !important;background: none !important;}
.search-box .gsc-control-cse .gsst_a {margin: 0;display: flex;text-decoration: none;}
.search-box .gsc-control-cse .gsc-search-button { display: none; }
.search-box .gsc-control-cse .gsc-search-box td.gsc-input {padding:0;}
/* Search box autocomplete */
.gsq_a table {box-shadow: none !important;}
.gsq_a td {text-align: left;}
.gsc-completion-container {background: black !important; color:white !important;}
.gsc-completion-selected {background: rgb(109, 109, 109) !important;}
/* Search box results */
.gsc-modal-background-image {background-color: #252525 !important;}
.gsc-results-wrapper-overlay {background: #151414 !important; border-radius: 8px !important;}

/* Google Ads */
.group:after {visibility: hidden; display: block; content: ""; clear: both; height: 0;}
* html .group { zoom: 1; } /* IE6 */
*:first-child+html .group { zoom: 1; } /* IE7 */

@media (max-width: 420px) {.gsc-results-wrapper-overlay{height: 100% !important; width: 100% !important;top:0 !important;left:0% !important; border-radius: 0 !important;}}
.gsc-webResult.gsc-result {border: none !important;}
.gsc-webResult.gsc-result:first-of-type {padding-top: 0;}
.gsc-webResult.gsc-result .gs-bidi-start-align.gs-snippet {margin-left: 70px; color: #bcbcbc !important;}
.gsc-above-wrapper-area, .gsc-adBlock {display: none !important;}
/* Search box on mobile */
.search-box-button { display: none; position: absolute;top: 41px;right: 17px;background: none;border: none;color: #282829;}
@media (max-width: 992px) {
  .search-box-button { display: block; }
  .search-box { display: none; top: 25px;}
  .logo {display: block;}
  body.search-box-visible .search-box-button { display: none !important; }
  body.search-box-visible .logo {display:none;}
  body.search-box-visible .search-box {display: block; width: auto; left: 80px;}
}
/* Adsense */
.adsense-container {margin: 24px auto; clear: both;}

/*Smallest Desktop Only*/
@media (max-width: 1192px) and (min-width: 993px) {
.w3-col .l6 {width: 100%;}
#main-content h1 {font-size: 1.444rem; margin: 0px;}
#main-content h2 {font-size: 1.222rem; margin: 0px;}
#main-content h3 {font-size: 1.111rem; margin: 8px 0px;}
}


                                                                                                  /*Up to Tablet - Sidebar Cutoff */
                                                                                                      @media (max-width: 992px) {
.logo {margin: 0px; max-width: 100%; display: flex; justify-content: center;}
#main-content-section {margin-left: 0px;}
#main-content h1 {line-height: normal; margin: 0px;}
#main-content h2 {font-size: 1.333rem; line-height: normal; margin: 0px;}
#main-content h3 {font-size: 1.222rem; margin: 8px 0px;}
#site-map-header h1, #site-map-content h2 {margin-top: 12px; margin-bottom: 12px;}
#site-map-content h3 {font-size: 1rem; margin-top: 6px; margin-bottom: 6px;}
#site-map-content span {font-size: 1.111rem;}
.htpc-section {display: block; margin: 24px auto; max-width: 60%;}
#right-column button {margin-left: 16px;}
/*Footer*/
.footer-content .env {margin-top: 24px;}
}

                                                                                                  /*Up to Normal & Larger Phones*/
                                                                                                    @media (max-width: 600px) {

.logo img {max-width: 225px;}
.top-menu-text.w3-bar-item {padding-left: 8px; padding-right: 8px;}
#main-content-section {margin-top: 115px;}
#main-content h1 {font-size: 1.444rem; margin: 0px;}
#main-content h2 {font-size: 1.222rem; margin: 0px;}
#main-content h3 {font-size: 1.111rem; margin: 8px 0px;}
#right-column button {margin-left: 0px;}
#right-column h1 {margin-top: 0px;}
table th {column-width: 100px;}
.htpc-section {max-width: 80%;}
.content-box {padding-top: 0px;}
.content-box, #right-column, .footer-content, .section-content {padding-left: 12px; padding-right: 12px;}
.content-box ul {padding-left: 12px;}
.practice-section-content {padding: 0px;}
.nav-buttons, .bot-nav-buttons {padding: 12px; margin-top: 0px; margin-bottom: 0px;}
.intro-box {padding:12px}
.popup> .popup__inner {padding: 1rem;}
#download-pdf-popup .inner {padding: 24px 12px 12px 12px;}
#download-pdf-popup img { width: 80%; height: auto; margin: 0px auto 12px auto;}
#download-pdf-popup .additional-info {margin-bottom: 0px;}
}

                                                                                                        /*Smallest Phones*/
                                                                                            @media only screen and (max-width: 374px) {
.nav {max-width: 100%;}
.logo {align-items: center;}
.logo img {max-width: 175px; height: auto;}
.open-btn {top: 25px; left: 25px;}
.search-box-button {top: 25px;}
}
  
     /*Dark Mode*/
@media (prefers-color-scheme: dark) {
/*Site Wide*/
body {color: #eee; background-color: #121212;}
body a {color: #809fff;}
.nav {background-color: #0F0F0F;}
.mobile-nav, .sub-menu {background-color: #111;}
.mobile-nav a {color: #818181;background-color: #111;}
.mobile-nav button {color: #818181;background-color: #111;}
.mobile-nav a:hover, .mobile-nav button:hover {color: #f1f1f1;}
.search-box-button {color: white;}
/*Home Page and Sidebar Buttons*/
.htpc-button {background-color: #005C69; color: #fff; border: 1px solid rgba(0,0,0,0.2);}
.help-button, .help-section button, .help-section a {background-color: #5C111B; color:#fff; border-color: #69131E;}
.help-button:hover, .help-section button:hover, .help-section a:hover {background-color: #A81E31; color:rgba(0,0,0,1); border-color: rgba(0,0,0,0.2);}

/*Main Content Background*/
#main-content {background-color: #121212; border-right: 1px solid black;}
article a {color: #E7903C;}
.separater {border-bottom-color: #E7903C}
.quote {color: #A69E32;}
/*Right Column Background*/
.content-page {background-color: #222222;}
/*Side Navigation*/
.sidenavigation {background-color: #0c121b; color: #CFCFCF;}
.sidenavigation a:hover {background-color:#abb1bc; color:#121212;}
.sidenavigation h4 {color: #5973FF;}
.sidenavigation h5 {color: #66CFFF;}
.this-page {background-color: #abb1bc; color: #121212;}
/*Previous/Next*/
.prvs-nxt {background-image: linear-gradient(180deg, #61122f, #511328);}
.prvs-nxt:hover {background-image: linear-gradient(0deg, #61122f, #511328);box-shadow:0 4px 10px 0 rgba(158,52,87,0.75),0 4px 20px 0 rgba(158,52,87,0.75)}
/*Item Page Boxes*/
.intro-box {color: #F0F0F0;background-image: linear-gradient(180deg, #0F1F1B, #0c1815);}
.start-button {color: #FFFFFF; background-image: linear-gradient(180deg, #4d5553, #2f3835, #2f3835); border: 1px solid #4d5553;}
.start-button:hover {color: #222222; background-image: linear-gradient(180deg, #b4b7b6, #909593, #909593);}
.section-box {background-color: #222222;}
.section-box h2 a {color: #E7903C;}
.section-box h2 a:hover {color: #ECB684;}
.section-buttons a {color: #F0F0F0; background-image: linear-gradient(180deg, #662836,#4D1E29); transition: 0.2s; }
.section-buttons a:hover {color: #F0F0F0; background-image: linear-gradient(180deg, #991E3D, #A62142);}
/*Email Popup and Contact Form*/
textarea, input {background-color: #222222; color: #EEEEEE; border: 1px solid #333333;}
input[type=text], select, textarea {border-color: black;}
.popup>.popup__inner{background: #121212; color: #EEEEEE;border: 1px solid grey;}
.popup>.popup__inner>.popup__close, #download-pdf-popup input{color:#EEEEEE;}
.popup a {color: #E7903C;}
}
