html {
  font-size: 18px;
}

body {
  color: #282829;
  font-family: Montserrat, Fallback, sans-serif;
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: 2px;
}

figure {
  margin: 0;
  padding: 0;
}

.htpc-button {
  color: #f0f0f0;
  background-color: #005c69;
  border: 1px solid #0003;
  border-radius: 3px;
}

.htpc-button:hover {
  color: #000;
  background-color: #00a0b5;
}

.help-button, .help-section button, .updated button, .small-box a {
  color: #fff;
  background-color: #930345;
  border: 1px solid #0003;
  border-radius: 3px;
}

.small-box a:hover, .help-button:hover {
  color: #000;
  background-color: #e0046b;
}

.social-share-buttons {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.twitter-share {
  margin-top: 7px;
}

.fb-share-button {
  margin-right: 8px;
}

:root {
  --nav-height: 105px;
}

.nav {
  width: 100%;
  height: var(--nav-height);
  z-index: 99;
  background-color: #fff;
  transition: transform .3s;
  position: fixed;
  top: 0;
}

.nav-items {
  width: 70%;
  display: inline-block;
}

.nav-items a {
  margin-top: 12px;
  font-size: .889rem;
}

.main-nav-btns {
  vertical-align: middle;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  padding: 8px 16px;
  font-weight: 700;
  text-decoration: none;
  transition: color .2s;
  display: inline-block;
  overflow: hidden;
}

.main-nav-btns:hover {
  color: #930345;
}

.main-nav-btns {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.skip-link {
  color: #fff;
  background: #fff;
  padding: 4px;
  font-weight: 700;
  transition: transform .3s;
  position: absolute;
  left: 50%;
  transform: translateY(-100%);
}

.skip-link:focus {
  transform: translateY(0%);
}

.logo {
  max-width: 80%;
  margin-left: 25px;
  margin-right: 15px;
  padding-top: 16px;
  padding-bottom: 24px;
  display: inline-block;
}

.logo img {
  width: 225px;
  height: 65px;
}

.open-btn {
  z-index: 1;
  font-size: 1.333rem;
  position: absolute;
  top: 37px;
  left: 30px;
}

.close-btn {
  z-index: 2;
  font-size: 44px;
  position: absolute;
  top: 22px;
  left: 30px;
}

.mobile-nav, .sub-menu {
  height: 100%;
  width: 0;
  white-space: nowrap;
  background-color: #e6e6e6;
  padding-bottom: 125px;
  transition: all .15s;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.mobile-nav {
  z-index: 1;
  padding-top: 127px;
}

.sub-menu {
  z-index: 2;
  padding-top: 15px;
}

.mobile-nav a {
  color: #111;
  background-color: #e6e6e6;
  padding: 8px 32px;
  font-size: 1rem;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.mobile-nav button {
  color: #111;
  width: 100%;
  text-align: left;
  background-color: #e6e6e6;
  border: none;
  padding: 8px 32px;
  font-size: 1rem;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.mobile-nav h3 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 8px 32px;
}

.mobile-nav a:hover, .mobile-nav button:hover {
  color: #d50202;
}

.sub-menu span {
  margin-left: 12px;
  font-size: 1.389rem;
}

body.nav-hidden > .nav, body.nav-hidden #sidenav {
  transform: translateY(calc(var(--nav-height) * -1));
}

.pdf-image {
  height: 300px;
  width: auto;
}

.view-none {
  display: none;
}

.footer-row {
  clear: both;
  background-color: #1a1a1a;
}

.footer-content {
  color: #fff;
  padding: 24px 32px;
  overflow: auto;
}

.footer-content figure {
  margin-bottom: 16px;
}

.intro-box {
  background-color: #8dbbcc;
  background-image: linear-gradient(#96c7d9, #8dbbcc);
  padding: 24px;
}

.intro-box h1 {
  margin-top: 0;
}

.intro-box p {
  font-weight: 600;
}

.start-button {
  color: #f0f0f0;
  background-image: linear-gradient(#508da9, #1d7899, #1d7899);
  border: 1px solid #508da9;
  border-radius: 3px;
  font-weight: 700;
}

.sticky {
  width: 100%;
  z-index: 3;
  padding-left: 12px;
  padding-right: 16px;
  position: fixed;
  top: 0;
}

.content-page {
  background-color: #f2efe9;
}

.sidenavigation {
  color: #0a0a0a;
  background-color: #ebf7f1;
  padding-bottom: 115px;
  transition: transform .3s;
}

.sidenavigation h5 {
  margin: 12px 0 8px 16px;
}

.sidenavigation h4 {
  margin: 0;
  padding: 12px 0 8px 16px;
}

.sidenavigation h3 {
  margin: 10px 0;
  padding-left: 16px;
}

.sidenavigation a {
  padding-left: 8px;
  font-size: .889rem;
  font-weight: 600;
}

.sidenavigation a:hover, .this-page {
  color: #fff;
  background-color: #4e7863;
}

#main-content-section {
  margin-top: 100px;
  margin-left: 250px;
}

#main-content {
  background-color: #fff;
  border-right: 1px solid #e6e6e6;
}

article a {
  color: #00e;
}

.separater {
  width: 85%;
  clear: both;
  border-bottom: 2px solid #a62142;
  margin: 35px auto;
}

.anchor {
  scroll-margin-top: 8.5em;
}

.quote {
  color: #a62142;
}

.nav-buttons {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0 24px;
}

.bot-nav-buttons {
  padding: 24px;
}

.prvs-nxt {
  color: #fff;
  background-image: linear-gradient(#21735c, #1e6652);
  border-radius: 3px;
  font-weight: 700;
}

.section-content {
  padding: 24px;
}

.page-sections {
  border: 1px solid gray;
  border-radius: 3px;
  margin-top: 24px;
}

.page-sections img {
  width: 100%;
  height: auto;
  border-radius: 3px 3px 0 0;
}

.section-box {
  background-color: #f7f7f7;
  border-radius: 0 0 3px 3px;
  padding: 12px;
}

.section-box h2 a {
  color: #000;
}

.section-box h2 a:hover {
  color: #d94016;
}

.section-buttons a {
  color: #f0f0f0;
  background-image: linear-gradient(0deg, #991e3d, #930345);
  border-radius: 3px;
  margin: 8px;
  font-size: .889rem;
  font-weight: 700;
  transition: all .2s;
}

.section-buttons a:hover {
  color: #f0f0f0;
  background-image: linear-gradient(#991e3d, #930345);
}

.content-box {
  padding: 24px;
}

.content-box h1 {
  margin-top: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #777;
}

td, th {
  text-align: center;
  word-break: break-word;
  border: 1px solid #272927;
  padding: 4px;
}

.table-note {
  margin-top: 5px;
}

input[type="text"], select, textarea {
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 16px;
  padding: 12px;
}

input[type="submit"] {
  color: #fff;
  background-color: #005c69;
  border: 1px solid #0003;
  border-radius: 3px;
  padding: 8px 16px;
}

input[type="submit"]:hover {
  color: #000;
  background-color: #00a0b5;
}

.container {
  border-radius: 5px;
  padding: 20px;
}

#site-map-header h1, #site-map-content h2, #site-map-content h3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

#site-map-content ul {
  margin: 0;
  padding-left: 16px;
}

#site-map-content a {
  text-underline-offset: 3px;
}

#site-map-content span, #site-map-content h2 {
  display: inline;
}

#site-map-content span {
  margin-right: 8px;
  font-size: 1.333rem;
  display: inline;
}

#right-column {
  padding: 24px;
}

#right-column input {
  width: 100%;
  max-width: 350px;
  padding: 8px 16px;
}

#right-column button {
  margin-top: 16px;
  padding: 8px 16px;
}

#right-column img {
  width: 100%;
  height: auto;
  max-width: 80%;
  margin: 24px auto;
  display: block;
}

#right-column h1 {
  text-align: center;
  font-size: 1.333rem;
}

.validation {
  color: #f16363;
  font-size: .9rem;
}

.popup {
  z-index: 10000;
  place-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
}

.popup > .popup__shadow {
  z-index: 10001;
  background-color: #000c;
  position: fixed;
  inset: 0;
}

.popup > .popup__inner {
  z-index: 10002;
  max-width: min(100vw - 50px, 550px);
  max-height: calc(100vh - 50px);
  background: #fff;
  border: 1px solid gray;
  border-radius: 6px;
  padding: 2.5rem;
  position: relative;
  overflow: auto;
}

.popup > .popup__inner > .popup__close {
  color: #000;
  font-size: 1.333rem;
  position: fixed;
  top: 25px;
  right: 25px;
}

#download-pdf-popup img {
  width: 275px;
  height: 208px;
  margin: 0 auto 48px;
  display: block;
}

#download-pdf-popup input {
  width: 100%;
  margin-bottom: 16px;
}

#download-pdf-popup button {
  color: #eee;
  background-color: #1e6652;
  border: 1px solid #164d3d;
  border-radius: 3px;
  padding: 4px 12px;
}

#download-pdf-popup a {
  color: #991e3d;
}

#download-pdf-popup .additional-info {
  font-size: .9rem;
}

#authPopup > .popup__inner {
  max-width: min(100vw - 50px, 350px);
}

#authPopup .auth-popup-wrapper {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

#authPopup .btn-google {
  background-color: #f24848;
}

#authPopup .btn-facebook {
  background-color: #6060ef;
}

.search-box {
  width: 200px;
  position: absolute;
  top: 22px;
  right: 20px;
}

.search-box .gsc-control-cse {
  margin-top: 15px;
  width: 100% !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
}

.search-box .gsc-control-cse input.gsc-input {
  height: 2rem !important;
  background: none !important;
  padding: 6px 10px !important;
}

.search-box .gsc-control-cse .gsc-search-box {
  box-shadow: none;
  border-radius: 3px;
  margin: 0;
}

.search-box .gsc-control-cse td {
  text-align: left;
  border: none;
  padding: 0;
}

.search-box .gsc-control-cse .gsc-input-box {
  background: none !important;
  border: none !important;
}

.search-box .gsc-control-cse .gsst_a {
  margin: 0;
  text-decoration: none;
  display: flex;
}

.search-box .gsc-control-cse .gsc-search-button {
  display: none;
}

.search-box .gsc-control-cse .gsc-search-box td.gsc-input {
  padding: 0;
}

.gsq_a table {
  box-shadow: none !important;
}

.gsq_a td {
  text-align: left;
}

.gsc-completion-container {
  color: #fff !important;
  background: #000 !important;
}

.gsc-completion-selected {
  background: #6d6d6d !important;
}

.gsc-modal-background-image {
  background-color: #252525 !important;
}

.gsc-results-wrapper-overlay {
  background: #151414 !important;
  border-radius: 8px !important;
}

.group:after {
  visibility: hidden;
  content: "";
  clear: both;
  height: 0;
  display: block;
}

* html .group, :first-child + html .group {
  zoom: 1;
}

@media (max-width: 420px) {
  .gsc-results-wrapper-overlay {
    height: 100% !important;
    width: 100% !important;
    border-radius: 0 !important;
    top: 0 !important;
    left: 0% !important;
  }
}

.gsc-webResult.gsc-result {
  border: none !important;
}

.gsc-webResult.gsc-result:first-of-type {
  padding-top: 0;
}

.gsc-webResult.gsc-result .gs-bidi-start-align.gs-snippet {
  margin-left: 70px;
  color: #bcbcbc !important;
}

.gsc-above-wrapper-area, .gsc-adBlock {
  display: none !important;
}

.search-box-button {
  color: #282829;
  background: none;
  border: none;
  display: none;
  position: absolute;
  top: 41px;
  right: 17px;
}

@media (max-width: 992px) {
  .search-box-button {
    display: block;
  }

  .search-box {
    display: none;
    top: 25px;
  }

  .logo {
    display: block;
  }

  body.search-box-visible .search-box-button {
    display: none !important;
  }

  body.search-box-visible .logo {
    display: none;
  }

  body.search-box-visible .search-box {
    width: auto;
    display: block;
    left: 80px;
  }
}

.adsense-container {
  clear: both;
  margin: 24px auto;
}

@media (max-width: 1192px) and (min-width: 993px) {
  .w3-col .l6 {
    width: 100%;
  }

  #main-content h1 {
    margin: 0;
    font-size: 1.444rem;
  }

  #main-content h2 {
    margin: 0;
    font-size: 1.222rem;
  }

  #main-content h3 {
    margin: 8px 0;
    font-size: 1.111rem;
  }
}

@media (max-width: 992px) {
  .logo {
    max-width: 100%;
    justify-content: center;
    margin: 0;
    display: flex;
  }

  #main-content-section {
    margin-left: 0;
  }

  #main-content h1 {
    margin: 0;
    line-height: normal;
  }

  #main-content h2 {
    margin: 0;
    font-size: 1.333rem;
    line-height: normal;
  }

  #main-content h3 {
    margin: 8px 0;
    font-size: 1.222rem;
  }

  #site-map-header h1, #site-map-content h2 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #site-map-content h3 {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 1rem;
  }

  #site-map-content span {
    font-size: 1.111rem;
  }

  .htpc-section {
    max-width: 60%;
    margin: 24px auto;
    display: block;
  }

  #right-column button {
    margin-left: 16px;
  }

  .footer-content .env {
    margin-top: 24px;
  }
}

@media (max-width: 600px) {
  .logo img {
    max-width: 225px;
  }

  .top-menu-text.w3-bar-item {
    padding-left: 8px;
    padding-right: 8px;
  }

  #main-content-section {
    margin-top: 115px;
  }

  #main-content h1 {
    margin: 0;
    font-size: 1.444rem;
  }

  #main-content h2 {
    margin: 0;
    font-size: 1.222rem;
  }

  #main-content h3 {
    margin: 8px 0;
    font-size: 1.111rem;
  }

  #right-column button {
    margin-left: 0;
  }

  #right-column h1 {
    margin-top: 0;
  }

  table th {
    column-width: 100px;
  }

  .htpc-section {
    max-width: 80%;
  }

  .content-box {
    padding-top: 0;
  }

  .content-box, #right-column, .footer-content, .section-content {
    padding-left: 12px;
    padding-right: 12px;
  }

  .content-box ul {
    padding-left: 12px;
  }

  .practice-section-content {
    padding: 0;
  }

  .nav-buttons, .bot-nav-buttons {
    margin-top: 0;
    margin-bottom: 0;
    padding: 12px;
  }

  .intro-box {
    padding: 12px;
  }

  .popup > .popup__inner {
    padding: 1rem;
  }

  #download-pdf-popup .inner {
    padding: 24px 12px 12px;
  }

  #download-pdf-popup img {
    width: 80%;
    height: auto;
    margin: 0 auto 12px;
  }

  #download-pdf-popup .additional-info {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 374px) {
  .nav {
    max-width: 100%;
  }

  .logo {
    align-items: center;
  }

  .logo img {
    max-width: 175px;
    height: auto;
  }

  .open-btn {
    top: 25px;
    left: 25px;
  }

  .search-box-button {
    top: 25px;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: #eee;
    background-color: #121212;
  }

  body a {
    color: #809fff;
  }

  .nav {
    background-color: #0f0f0f;
  }

  .mobile-nav, .sub-menu {
    background-color: #111;
  }

  .mobile-nav a, .mobile-nav button {
    color: #818181;
    background-color: #111;
  }

  .mobile-nav a:hover, .mobile-nav button:hover {
    color: #f1f1f1;
  }

  .search-box-button {
    color: #fff;
  }

  .htpc-button {
    color: #fff;
    background-color: #005c69;
    border: 1px solid #0003;
  }

  .help-button, .help-section button, .help-section a {
    color: #fff;
    background-color: #5c111b;
    border-color: #69131e;
  }

  .help-button:hover, .help-section button:hover, .help-section a:hover {
    color: #000;
    background-color: #a81e31;
    border-color: #0003;
  }

  #main-content {
    background-color: #121212;
    border-right: 1px solid #000;
  }

  article a {
    color: #e7903c;
  }

  .separater {
    border-bottom-color: #e7903c;
  }

  .quote {
    color: #a69e32;
  }

  .content-page {
    background-color: #222;
  }

  .sidenavigation {
    color: #cfcfcf;
    background-color: #0c121b;
  }

  .sidenavigation a:hover {
    color: #121212;
    background-color: #abb1bc;
  }

  .sidenavigation h4 {
    color: #5973ff;
  }

  .sidenavigation h5 {
    color: #66cfff;
  }

  .this-page {
    color: #121212;
    background-color: #abb1bc;
  }

  .prvs-nxt {
    background-image: linear-gradient(#61122f, #511328);
  }

  .prvs-nxt:hover {
    background-image: linear-gradient(0deg, #61122f, #511328);
    box-shadow: 0 4px 10px #9e3457bf, 0 4px 20px #9e3457bf;
  }

  .intro-box {
    color: #f0f0f0;
    background-image: linear-gradient(#0f1f1b, #0c1815);
  }

  .start-button {
    color: #fff;
    background-image: linear-gradient(#4d5553, #2f3835, #2f3835);
    border: 1px solid #4d5553;
  }

  .start-button:hover {
    color: #222;
    background-image: linear-gradient(#b4b7b6, #909593, #909593);
  }

  .section-box {
    background-color: #222;
  }

  .section-box h2 a {
    color: #e7903c;
  }

  .section-box h2 a:hover {
    color: #ecb684;
  }

  .section-buttons a {
    color: #f0f0f0;
    background-image: linear-gradient(#662836, #4d1e29);
    transition: all .2s;
  }

  .section-buttons a:hover {
    color: #f0f0f0;
    background-image: linear-gradient(#991e3d, #a62142);
  }

  textarea, input {
    color: #eee;
    background-color: #222;
    border: 1px solid #333;
  }

  input[type="text"], select, textarea {
    border-color: #000;
  }

  .popup > .popup__inner {
    color: #eee;
    background: #121212;
    border: 1px solid gray;
  }

  .popup > .popup__inner > .popup__close, #download-pdf-popup input {
    color: #eee;
  }

  .popup a {
    color: #e7903c;
  }
}

/*# sourceMappingURL=index.71c8874d.css.map */
